import React from "react";
import { renderText, renderHtml } from "utils/renderHelpers";
import { renderFluidImage } from "utils/imageHelpers";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import styles from "./Sponsor.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default ({
  primary,
  noTopPadding = false,
  isGroup = false,
  showHeading = true
}) => {
  const { sponsor } = primary;
  const doc = sponsor.document[0].data;
  const { name, logo, tagline, description } = doc;
  const hasTagline = tagline && tagline.text && tagline.text.length > 0;
  return (
    <Section
      className={cx({ sponsor: true, noTopPadding: noTopPadding === true })}
    >
      <Container>
        <Columns mobile multiline centered>
          {showHeading && (
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              <h4>{isGroup ? `Sponsors` : `Sponsor`}</h4>
            </Columns.Column>
          )}
          <Columns.Column
            mobile={{ size: 11 }}
            tablet={{ size: hasTagline ? 2 : 3 }}
          >
            {renderText(name, "span", "is-sr-only")}
            <figure>{renderFluidImage(logo, null, { alt: name.text })}</figure>
          </Columns.Column>
          {hasTagline && (
            <Columns.Column mobile={{ size: 11 }} tablet={{ size: 5 }}>
              {renderHtml(tagline, "article")}
            </Columns.Column>
          )}
          <Columns.Column
            mobile={{ size: 11 }}
            tablet={{ size: hasTagline ? 5 : 9 }}
          >
            {renderHtml(
              description,
              "div",
              cx({ description: true, padded: hasTagline !== true })
            )}
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};
