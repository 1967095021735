function getYear(d) {
  return d.split(' ')[1];
}

function getMonth(d) {
  return d.split(' ')[0];
}

export default (startDate, endDate) => {
  if (!startDate || !endDate) {
    return null;
  }
  const startYear = getYear(startDate);
  const endYear = getYear(endDate);
  const isSameYear = startYear === endYear;

  if (isSameYear) {
    return `${getMonth(startDate)} - ${getMonth(endDate)} ${startYear}`;
  } else {
    return `${startDate} - ${endDate}`;
  }
};
