import React, { PureComponent } from "react";
import { renderHtml, renderText } from "utils/renderHelpers";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Dialog from "react-a11y-dialog";
import DialogCarousel from "components/DialogCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalEmitter from "utils/GlobalEmitter";
import { JuryCarouselItemRenderer } from "../Jury";
import Route from "route-parser";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import events from "utils/events";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import getStartEndDate from "utils/getStartEndDate";
import { navigate } from "gatsby";
import querystring from "querystring";
import { renderFluidImage } from "utils/imageHelpers";
import styles from "./StaffListing.module.scss";
import uid from "utils/uid";

const cx = classNames.bind(styles);

const getRealItems = (items) => {
  return items
    .filter((item) => {
      const { staff_member } = item;
      if (!staff_member) {
        return false;
      }
      const { doc } = staff_member;
      if (!doc || doc.length === 0 || !doc[0].data) {
        return false;
      }
      return true;
    })
    .filter(Boolean);
};

const getStaff = (items) => {
  let _staff = getRealItems(items)
    .map((item) => {
      if (!item || !item.staff_member) {
        return null;
      }
      if (item.staff_member.doc) {
        item.staff_member.doc[0].data.modalSlug =
          item.staff_member.doc[0].modalSlug;
        item.staff_member.doc[0].data.slug = item.staff_member.doc[0].slug;
        return item.staff_member.doc[0].data;
      }
      return null;
    })
    .filter(Boolean);
  return _staff;
};

export default class StaffListing extends PureComponent {
  dialog;
  canOpenDialog = true;

  state = {
    active: false,
    dialogShowing: false,
    dialogTitle: null,
    dialogContents: null,
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (typeof window === "undefined") {
      return;
    }
    if (!props.location) {
      return;
    }
    let state = {};
    const routeParser = new Route(`/${props.slug}/:staff/:person(/)`);
    const route = routeParser.match(props.location.pathname);
    const { person } = route;
    if (!this.staff) {
      this.staff = getStaff(props.items);
    }
    const staff = this.staff;

    const prefix = props.dialogPrefix || `staff`;
    const matches = staff.filter((p) => p.modalSlug === person);

    if (matches.length > 0) {
      let match = matches[0];
      let url = match.modalSlug;

      state = {
        dialogShowing: true,
        dialogTitle: `${match.first_name.text} ${match.last_name.text}`,
        dialogContents: (
          <DialogCarousel
            items={staff.map((item, idx) => {
              return (
                <JuryCarouselItemRenderer
                  key={`${prefix}-${idx}`}
                  {...item}
                  show_author_link={match.slug ? true : false}
                />
              );
            })}
            urls={staff.map(({ modalSlug }) => `${prefix}/${modalSlug}`)}
            url={`${prefix}/${url}`}
            baseUrl={`/${props.slug}`}
          />
        ),
      };
      this.setState(state);
      return;
    }
    this.setState({ dialogShowing: false });
  }

  checkQueryString() {
    if (!this.props.location) {
      return;
    }
    const qs = querystring.parse(this.props.location.search.substr(1));
    if (qs && (qs.hasOwnProperty("writer") || qs.hasOwnProperty("staff"))) {
      let key = Object.keys(qs)[0];
      setTimeout(() => {
        navigate(`/${this.props.slug}/${key}/${qs[key]}/`, {
          state: { modal: true },
        });
      }, 1000);
    }
  }

  onResize = () => {
    if (this.state.dialogShowing) {
      this.positionDialog();
    }
  };

  positionDialog() {
    // if (this.state.dialogContents) {
    //   let dialog = this.dialog ? this.dialog.dialog : null;
    //   if (!dialog) {
    //     return;
    //   }
    //   clearTimeout(this.dialogTimeout);
    //   this.dialogTimeout = setTimeout(() => {
    //     let width = dialog.getBoundingClientRect().width;
    //     let height = dialog.getBoundingClientRect().height;
    //     dialog.style.top = `${Math.round(
    //       window.innerHeight * 0.5 - height * 0.5
    //     )}px`;
    //     dialog.style.left = `${Math.round(
    //       window.innerWidth * 0.5 - width * 0.5
    //     )}px`;
    //   }, 0);
    // }
  }

  hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    this.dialog.dialog.parentNode
      .querySelector(".dialog-overlay")
      .removeEventListener("click", this.hideDialog);
    this.dialog.off("hide", this.hideDialog);
    this.isDialogShowing = false;
    if (!this.canOpenDialog) {
      return;
    }
    navigate(`/${this.props.slug}/`, { state: { modal: true } });
  };

  onShowDialog = () => {
    document.documentElement.style.overflow = "hidden";
  };

  checkDialog = () => {
    if (!this.canOpenDialog) {
      return;
    }
    if (this.dialog) {
      this.dialog.on("show", this.onShowDialog);
    }

    if (this.dialog && this.state.dialogShowing) {
      this.positionDialog();
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .addEventListener("click", this.hideDialog);
      this.dialog.show();
      this.dialog.on("hide", this.hideDialog);

      this.isDialogShowing = true;
      clearTimeout(this.dialogTimeout);
      this.dialogTimeout = setTimeout(() => {
        this.positionDialog();
      }, 0);
    } else if (
      this.dialog &&
      !this.state.dialogShowing &&
      this.isDialogShowing
    ) {
      this.isDialogShowing = false;
      this.dialog.hide();
    }
  };

  componentDidMount() {
    this.canOpenDialog = true;

    clearTimeout(this.dialogTimeout);
    this.dialogTimeout = setTimeout(() => {
      GlobalEmitter.on(events.resize, this.onResize);
      this.UNSAFE_componentWillReceiveProps(this.props);
      this.checkQueryString();
      this.checkDialog();
    }, 750);
  }

  componentWillUnmount() {
    this.staff = null;
    this.canOpenDialog = false;
    clearTimeout(this.dialogTimeout);
    GlobalEmitter.off(events.resize, this.onResize);
  }

  componentDidUpdate() {
    this.checkDialog();
  }

  constructor(props) {
    super(props);
    this.id = uid();
  }

  render() {
    const { primary, items, slug, dialogPrefix, showDates, showSelectedBy } =
      this.props;
    const { dialogTitle, dialogContents } = this.state;
    const { heading, theme } = primary;
    const prefix = dialogPrefix || `staff`;
    const realItems = getRealItems(items);
    const has5 = realItems.length === 5;

    return (
      <React.Fragment>
        <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
          <Container>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {renderText(heading, "h4")}
            </Columns.Column>
            <Columns mobile multiline>
              {realItems.map((item, idx) => {
                const { staff_member } = item;
                const { doc } = staff_member;

                let {
                  first_name,
                  last_name,
                  image,
                  job_title,
                  email,
                  phone_number,
                  programs,
                } = doc[0].data;

                let { uid, modalSlug } = doc[0];
                const title = `${first_name.text} ${last_name.text}`;

                if (!modalSlug) {
                  modalSlug = uid;
                }

                const { carousel } = image;
                let showYear = false;
                let year = null;
                let showSelected = false;
                let selectedByAuthor = null;

                // console.log(`/${slug}/${prefix}/${modalSlug}`);

                if (
                  (showDates || showSelectedBy) &&
                  programs &&
                  programs.length > 0
                ) {
                  let bertonHouseProgram = programs.filter(
                    (p) =>
                      p.program &&
                      p.program.raw &&
                      p.program.raw.uid === "berton-house-writers-residency"
                  )[0];

                  let risingStarsProgram = programs.filter(
                    (p) =>
                      p.program &&
                      p.program.raw &&
                      p.program.raw.uid === "rising-stars"
                  )[0];

                  if (bertonHouseProgram) {
                    showYear = true;
                    year =
                      getStartEndDate(
                        bertonHouseProgram.start_date,
                        bertonHouseProgram.end_date
                      ) || bertonHouseProgram.year;
                  }

                  if (risingStarsProgram) {
                    showYear = false;
                    showSelected = true;
                    selectedByAuthor =
                      risingStarsProgram.selected_by &&
                      risingStarsProgram.selected_by.document
                        ? risingStarsProgram.selected_by.document[0]
                        : null;
                  }
                }

                return (
                  <Columns.Column
                    key={`stafflisting-${idx}`}
                    mobile={{ size: 6 }}
                    tablet={{ size: has5 ? `one-fifth` : 3 }}
                  >
                    <article className={styles.staff}>
                      <a
                        tabIndex={0}
                        href={`#modal`}
                        title={title}
                        onClick={() => {
                          navigate(`/${slug}/${prefix}/${modalSlug}/`);
                        }}
                      >
                        <figure className={styles.image}>
                          {renderFluidImage(carousel || image, null, {
                            maxWidth: [320, 300],
                          })}
                        </figure>
                        <h2>{title}</h2>
                      </a>
                      {renderText(job_title, "p")}
                      {email && email.text && email.text.length > 0 && (
                        <a title={email.text} href={`mailto:${email.text}`}>
                          {email.text}
                        </a>
                      )}
                      {phone_number &&
                        phone_number.text &&
                        phone_number.text.length > 0 && (
                          <a
                            title={phone_number.text}
                            href={`tel:${phone_number.text}`}
                          >
                            {phone_number.text}
                          </a>
                        )}
                      {showYear && renderText(year, "p")}
                      {showSelected &&
                        selectedByAuthor &&
                        renderHtml(
                          `Selected by ${
                            selectedByAuthor &&
                            selectedByAuthor.data.first_name.text
                          } ${selectedByAuthor.data.last_name.text}`
                        )}
                      <a
                        tabIndex={0}
                        href={`#modal`}
                        // to={`/${slug}/${prefix}/${modalSlug}`}
                        onClick={() => {
                          navigate(`/${slug}/${prefix}/${modalSlug}/`);
                        }}
                        title={title}
                        className={styles.moreLink}
                      >
                        <span>
                          {selectedByAuthor ? `More info` : `See bio`}
                        </span>
                        <i className="icon">
                          <FontAwesomeIcon icon={faPlus} />
                        </i>
                      </a>
                    </article>
                  </Columns.Column>
                );
              })}
            </Columns>
          </Container>
        </Section>
        <Dialog
          key={`staff-listing-dialog-${this.id}`}
          id={`staff-listing-dialog-${this.id}`}
          appRoot="#__gatsby"
          dialogRoot="#dialog-root"
          dialogRef={(dialog) => {
            this.dialog = dialog;
          }}
          title={dialogTitle || ``}
          closeButtonContent={
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          }
          classNames={{
            element: "dialog-content",
            overlay: "dialog-overlay",
            base: "dialog",
            closeButton: "dialog-close",
            title: "is-sr-only",
          }}
        >
          {dialogContents || ``}
        </Dialog>
      </React.Fragment>
    );
  }
}
