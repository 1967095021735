import React, { Component } from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import { QuoteContent } from 'slices/Quote';
import uid from 'utils/uid';
import events from 'utils/uid';
import GlobalEmitter from 'utils/GlobalEmitter';
import posed, { PoseGroup } from 'react-pose';
import styles from './QuoteGallery.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);
const timing = 300;
const multiplier = 1;

const config = {
  enter: {
    opacity: 1,
    beforeChildren: timing * multiplier,
    transition: {
      opacity: {
        type: 'tween',
        ease: 'easeOut',
        duration: timing * multiplier
      }
    }
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        type: 'tween',
        ease: 'easeIn',
        duration: timing * multiplier * 0.75
      }
    }
  }
};
const CarouselItem = posed.div(config);

class QuoteGallery extends Component {
  get height() {
    if (!this.currentItem) {
      return 400;
    }
    return this.currentItem.getBoundingClientRect().height + 10;
  }

  get currentItem() {
    return document.querySelector(
      `#${this.id}-quote-${this.state.currentIndex}`
    );
  }

  constructor(props) {
    super(props);
    this.id = `quote-gallery-${uid()}`;
    this.state = {
      currentIndex: 0,
      total: props.items.length
    };
  }

  setHeight = () => {
    this.track.style.height = `${this.height}px`;
  };

  componentDidMount() {
    this.setHeight();
    GlobalEmitter.on(events.resize, this.setHeight);
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.resize, this.setHeight);
  }

  next = () => {
    let { currentIndex, total } = this.state;
    currentIndex++;
    if (currentIndex >= total) {
      currentIndex = 0;
    }
    this.quoteChange(currentIndex);
  };

  prev = () => {
    let { currentIndex, total } = this.state;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = total - 1;
    }
    this.quoteChange(currentIndex);
  };

  quoteChange(currentIndex) {
    this.setState(
      {
        currentIndex
      },
      () => {
        this.setHeight();
      }
    );
  }

  render() {
    const { theme } = this.props.primary;
    const { items } = this.props;
    const total = items.length;
    return (
      <Section
        className={`${theme || `grey`} ${cx({
          section: true,
          [theme || `grey`]: true
        })}`}
      >
        <Container>
          <Columns mobile centered>
            <Columns.Column
              id={this.id}
              mobile={{ size: 9 }}
              tablet={{ size: 8 }}
            >
              <div ref={ref => (this.track = ref)} className={styles.track}>
                <PoseGroup animateOnMount={true}>
                  <CarouselItem
                    className={styles.item}
                    key={`quote-${this.state.currentIndex}`}
                  >
                    <article
                      className={styles.quote}
                      id={`${this.id}-quote-${this.state.currentIndex}`}
                    >
                      <QuoteContent
                        primary={{ ...items[this.state.currentIndex] }}
                        isAnimated={true}
                      />
                    </article>
                  </CarouselItem>
                </PoseGroup>
              </div>
            </Columns.Column>
          </Columns>
          <fieldset
            className={styles.controls}
            aria-label="carousel buttons"
            aria-controls={this.id}
          >
            <button
              aria-label="previous"
              type="button"
              onClick={this.prev}
              className={cx({
                carouselButton: true,
                disabled: total <= 1
              })}
            >
              <i className="icon" />
              Previous
            </button>
            <button
              aria-label="next"
              type="button"
              onClick={this.next}
              className={cx({
                carouselButton: true,
                disabled: total <= 1
              })}
            >
              <i className="icon" />
              Next
            </button>
          </fieldset>
        </Container>
      </Section>
    );
  }
}

export default QuoteGallery;
