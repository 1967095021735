import React from 'react';
import { renderText, renderHtml } from 'utils/renderHelpers';
import { renderFluidImage } from 'utils/imageHelpers';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import styles from './SponsorGrid.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default ({ primary, items, id }) => {
  const { heading } = primary;

  return (
    <Section
      className={cx({ sponsorGrid: true })}
      style={{ marginBottom: `-3rem` }}
    >
      <Container>
        <Columns mobile multiline centered>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            <h4>{heading.text}</h4>
          </Columns.Column>
          <Columns.Column mobile={{ size: 11 }} tablet={{ size: 12 }}>
            <Columns mobile multiline>
              {items.map((item, idx) => {
                if (!item || !item.sponsor || !item.sponsor.document) {
                  return null;
                }
                const doc = item.sponsor.document[0].data;
                const { name, logo, tagline, description } = doc;
                const hasTagline =
                  tagline && tagline.text && tagline.text.length > 0;
                return (
                  <Columns.Column
                    key={`${id}-sponsor-${idx}`}
                    mobile={{ size: 11 }}
                    tablet={{ size: 11 }}
                    desktop={{ size: 6 }}
                    style={{ marginBottom: `3rem` }}
                  >
                    <Columns mobile multiline>
                      <Columns.Column
                        mobile={{ size: 12 }}
                        tablet={{ size: hasTagline ? 8 : 3 }}
                      >
                        {renderText(name, 'span', 'is-sr-only')}
                        <figure>
                          {renderFluidImage(logo, null, { alt: name.text })}
                        </figure>
                      </Columns.Column>
                      {hasTagline && (
                        <Columns.Column
                          mobile={{ size: 12 }}
                          tablet={{ size: 5 }}
                        >
                          {renderHtml(tagline, 'article')}
                        </Columns.Column>
                      )}
                      <Columns.Column
                        mobile={{ size: 12 }}
                        tablet={{ size: hasTagline ? 5 : 8 }}
                      >
                        {renderHtml(
                          description,
                          'div',
                          cx({ description: true })
                        )}
                      </Columns.Column>
                    </Columns>
                  </Columns.Column>
                );
              })}
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};
