import { PrismicLink, renderHtml, renderText } from "utils/renderHelpers";
import React, { PureComponent } from "react";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTMLContent from "../HTMLContent";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import posed from "react-pose";
import { renderFluidImage } from "utils/imageHelpers";
import styles from "./LargeSectionCallout.module.scss";

const cx = classNames.bind(styles);

const Expandable = posed.div({
  closed: { height: 0 },
  open: { height: `100%` },
});

class LargeSectionCallout extends PureComponent {
  state = { expanded: false };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { primary, items } = this.props;
    // console.log({ primary: primary.anchor_id, items });
    const { expanded } = this.state;
    const {
      layout,
      image,
      category,
      heading,
      paragraph,
      bleed,
      theme,
      anchor_id,
      expandable_content,
    } = primary;
    //let isRTL = true;
    let isRTL = layout === "RTL";
    let isFullBleed = bleed === "true";
    let hasLinks = items.filter((item) => item.link).length > 0;
    const hasExpandableContent =
      expandable_content &&
      expandable_content.text &&
      expandable_content.text.length > 0;

    return (
      <Section
        id={anchor_id || null}
        className={`${theme || `grey`} ${cx({
          section: true,
          [theme || `grey`]: true,
        })}`}
      >
        <Container>
          <Columns>
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{ size: 6 }}
              className={`is-flex is-order-1-mobile${
                isRTL ? ` is-order-2-tablet` : ` is-order-1-tablet`
              } ${styles.flexColumn}`}
            >
              {image && image.url && (
                <figure
                  className={cx({
                    image: true,
                    bleed: isFullBleed,
                    rtl: isRTL,
                  })}
                  style={{
                    height:
                      isFullBleed && image.dimensions
                        ? `${
                            (image.dimensions.height / image.dimensions.width) *
                            50
                          }vw`
                        : null,
                  }}
                >
                  {renderFluidImage(image, null, {
                    maxWidth: [360, 360, 360, 520, 680],
                  })}
                </figure>
              )}
            </Columns.Column>
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{ size: 6 }}
              className={`is-flex is-order-2-mobile ${
                isRTL ? ` is-order-1-tablet` : ` is-order-2-tablet`
              }`}
            >
              <article className={cx({ callout: true, rtl: isRTL })}>
                {renderText(category, "h4")}
                {renderText(heading, "h2")}
                {renderHtml(paragraph, "div", styles.content)}
                {hasExpandableContent && (
                  <div class={styles.expandable}>
                    <button onClick={this.toggleExpanded}>
                      <span>Read More</span>
                      <i className="icon">
                        <FontAwesomeIcon icon={expanded ? faMinus : faPlus} />
                      </i>
                    </button>
                    <Expandable
                      className={styles.expandableContent}
                      initialPose={"closed"}
                      pose={expanded ? "open" : "closed"}
                    >
                      <HTMLContent columns={1} html={expandable_content.html} />
                    </Expandable>
                  </div>
                )}
                {hasLinks && (
                  <ul className={styles.links}>
                    {items.map(({ link_label, link }, idx) => {
                      return (
                        <li key={`lsc-link-${idx}`}>
                          <PrismicLink link={link} label={link_label} />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </article>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    );
  }
}

LargeSectionCallout.propTypes = {};

export default LargeSectionCallout;
