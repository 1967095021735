import { PrismicLink, renderHtml, renderText } from "utils/renderHelpers";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import React from "react";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import styles from "./SocialEmbeds.module.scss";

const cx = classNames.bind(styles);

function ItemRenderer(props) {
  const ref = React.useRef();

  React.useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const iframe = ref.current.querySelector("iframe");
      if (iframe) {
        const iframeObserver = new MutationObserver((mutations) => {
          if (parseInt(iframe.getAttribute("height"))) {
            iframe.style.height = `${parseInt(
              iframe.getAttribute("height")
            )}px`;
          }
        });
        iframeObserver.observe(iframe, {
          attributes: true,
          childList: false,
          characterData: false,
          subtree: false,
        });
        observer.disconnect();
      }
    });

    observer.observe(ref.current, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });
  }, []);

  return <div ref={ref} {...props} />;
}

function SocialEmbeds({ primary, items }) {
  const {
    theme,
    heading,
    number_of_columns_mobile = "2",
    number_of_columns_tablet = "4",
    number_of_columns = "5",
  } = primary;

  const renderableItems = React.useMemo(() => {
    let result = items.map((item) => {
      if (item?.embed?.provider_name?.toLowerCase() === `instagram`) {
        item.embed.html = item.embed.html.replace("data-instgrm-captioned", "");
      }
      return item;
    });
    return result.concat(result);
  }, [items]);

  // console.log({ renderableItems });

  return (
    <Section className={`${theme} ${cx({ [theme]: true, section: true })}`}>
      <Container>
        <Columns mobile multiline>
          {heading && (
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              <h2 className={cx({ title: true, heading: true })}>
                {heading?.text}
              </h2>
            </Columns.Column>
          )}
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            <Columns mobile className={styles.track}>
              {renderableItems?.map((item, idx) => (
                <Columns.Column
                  key={`embed-${idx}`}
                  mobile={{ size: 12 / parseInt(number_of_columns_mobile) }}
                  tablet={{ size: 12 / parseInt(number_of_columns_tablet) }}
                  desktop={{
                    size:
                      parseInt(number_of_columns) === 5
                        ? 3
                        : 12 / parseInt(number_of_columns_tablet),
                  }}
                  className={`social-embed ${
                    item?.embed?.provider_name?.toLowerCase() || ``
                  }`}
                >
                  <ItemRenderer
                    dangerouslySetInnerHTML={{
                      __html: item?.embed?.html || item?.code?.text,
                    }}
                    className={styles.embed}
                  />
                </Columns.Column>
              ))}
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
}

export default SocialEmbeds;
