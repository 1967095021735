import React, { Component } from "react";

import AboutAwardBlock from "slices/AboutAwardBlock";
import BigCalloutLinks from "slices/BigCalloutLinks";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Dialog from "react-a11y-dialog";
import DialogCarousel from "components/DialogCarousel";
import { FinalistCarouselItemRenderer } from "slices/Finalists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import FullWidthCallouts from "slices/FullWidthCallouts";
import FullWidthImage from "slices/FullWidthImage";
import Gallery from "slices/Gallery";
import Gallery2024 from "slices/Gallery2024";
import GlobalEmitter from "utils/GlobalEmitter";
import HTMLContent from "slices/HTMLContent";
import Helmet from "react-helmet";
import Hero from "slices/Hero";
import JotFormEmbed from "components/JotFormEmbed";
import LargeSectionCallout from "slices/LargeSectionCallout";
import Quote from "slices/Quote";
import QuoteGallery from "slices/QuoteGallery";
import Route from "route-parser";
import SocialEmbeds from "slices/SocialEmbeds";
import Sponsor from "slices/Sponsor";
import SponsorGrid from "slices/SponsorGrid";
import StaffListing from "slices/StaffListing";
import { VideoSection } from "slices/Video";
import Winner from "slices/Winner";
import WritersBooksCarousel from "slices/WritersBooksCarousel";
import events from "utils/events";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { getOgImageFromGallery } from "utils/getOgImageFromGallery";
import { graphql } from "gatsby";
import heroStyles from "slices/Hero/Hero.module.scss";
import { navigate } from "gatsby-link";
import querystring from "querystring";
import { renderText } from "utils/renderHelpers";
import slugify from "slugify";
import uid from "utils/uid";
import { sliceTypeMatches } from "utils/slices";

// Slices

// graphql

const stripFormTags = (html) => {
  let result = html.replace(/style="[^"]*"/g, "");
  return result;
};

let suppressDialog = false;
const setSuppressDialog = (bool) => {
  suppressDialog = bool === true;
};

const getFinalistsDialogContent = (
  stateObj,
  baseSlug,
  url,
  finalists,
  title,
  year
) => {
  if (!finalists) {
    return null;
  }
  stateObj.dialogContents = (
    <DialogCarousel
      items={finalists.map((finalist, idx) => {
        const isAuthor = !finalist.finalistData.book;
        return (
          <FinalistCarouselItemRenderer
            key={`finalist-${idx}`}
            isAuthor={isAuthor}
            finalist={finalist}
            title={title}
            year={year}
            setSuppressDialog={setSuppressDialog}
          />
        );
      })}
      urls={finalists.map(({ data, finalistData }) => {
        const isAuthor = !finalistData.book;
        if (isAuthor) {
          return slugify(
            `${data.first_name.text.toLowerCase()} ${data.last_name.text.toLowerCase()}`
          );
        }
        return `${slugify(
          finalistData.book.document[0].data.title.text.toLowerCase()
        )}`;
      })}
      url={url}
      baseUrl={baseSlug}
    />
  );
  return stateObj;
};

class GuidelinesDialogContent extends Component {
  render() {
    const {
      guidelines_title,
      guidelines_subtitle,
      guidelines_top_paragraph,
      form_embed,
      source_code,
      pdf_application,
      external_url,
      body1,
    } = this.props;

    let hasFormEmbed = form_embed && form_embed.embed_url;
    let isJotform =
      hasFormEmbed && form_embed.provider_name.toLowerCase() === "jotform";

    let hasFormSourceCode = source_code && source_code.text;
    let hasPdf = pdf_application && pdf_application.url;

    let hasExternalLink =
      external_url && external_url.text && external_url.text.length > 0;

    let isDefinitelyEmbed = !hasExternalLink && !hasPdf;

    return (
      <React.Fragment>
        <Container>
          <Columns mobile multiline centered>
            <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
              <header>
                {renderText(guidelines_title, `h1`)}
                {renderText(
                  guidelines_subtitle,
                  `h4`,
                  `has-text-red has-font-weight-bold`
                )}
              </header>
            </Columns.Column>
          </Columns>
          <Columns mobile multiline centered>
            <Columns.Column
              mobile={{ size: 11 }}
              tablet={{ size: hasFormEmbed ? 5 : 10 }}
            >
              <HTMLContent html={guidelines_top_paragraph} columns={1} />
            </Columns.Column>
            {hasPdf && (
              <Columns.Column
                style={{ textAlign: `center` }}
                mobile={{ size: 11 }}
                tablet={{ size: 5 }}
              >
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="anchor"
                  href={pdf_application.url}
                  title="Download Application Form"
                >
                  <span>Application Form</span>
                </a>
              </Columns.Column>
            )}
            {hasExternalLink && (
              <Columns.Column
                mobile={{ size: 11 }}
                tablet={{ size: 5 }}
                style={{ textAlign: `center` }}
              >
                <a
                  rel="noopener noreferrer"
                  className="anchor"
                  href={external_url.text}
                  target="_blank"
                  title="Go to Application Form"
                >
                  <span>Application Form</span>
                </a>
              </Columns.Column>
            )}
            {isDefinitelyEmbed && (
              <React.Fragment>
                {(hasFormSourceCode || (hasFormEmbed && isJotform)) && (
                  <Columns.Column
                    mobile={{ size: 11 }}
                    tablet={{ size: 5 }}
                    style={{ textAlign: `center` }}
                  >
                    <a
                      className="anchor"
                      href="#form-embed"
                      title="Jump to submission form"
                    >
                      <span>Submission form</span>
                      <i className="icon">
                        <FontAwesomeIcon icon={faCaretDown} />
                      </i>
                    </a>
                  </Columns.Column>
                )}
                {hasFormEmbed && !isJotform && (
                  <Columns.Column
                    mobile={{ size: 11 }}
                    tablet={{ size: 5 }}
                    style={{ textAlign: `center` }}
                  >
                    <a
                      rel="noopener noreferrer"
                      className="anchor"
                      href={form_embed.embed_url}
                      target="_blank"
                      title="Goto to submission form on Submittable"
                    >
                      <span>Submission form</span>
                    </a>
                  </Columns.Column>
                )}
              </React.Fragment>
            )}
            {body1 &&
              body1.length &&
              body1.map(({ primary, items, slice_type, id }, idx) => {
                return (
                  <Columns.Column
                    mobile={{ size: 11 }}
                    tablet={{ size: 10 }}
                    key={id}
                  >
                    <HTMLContent columns={1} html={primary.heading} />
                    {items.map((item) => (
                      <HTMLContent
                        key={`${id}-${idx}`}
                        html={item.content}
                        columns={parseInt(primary.columns)}
                      />
                    ))}
                  </Columns.Column>
                );
              })}
          </Columns>
          {hasFormSourceCode ? (
            <Columns mobile centered>
              <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
                <article
                  id="form-embed"
                  className="form-embed"
                  dangerouslySetInnerHTML={{
                    __html: stripFormTags(source_code.text),
                  }}
                />
              </Columns.Column>
            </Columns>
          ) : hasFormEmbed && isJotform ? (
            <Columns mobile centered>
              <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
                <JotFormEmbed src={form_embed.embed_url} />
              </Columns.Column>
            </Columns>
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

const parseFinalists = (finalists, year, uid) => {
  let result = finalists.edges.map(({ node, slug }) => ({
    slug,
    ...node,
  }));
  result.forEach((f) => {
    f.finalistData = f.data.programs.filter((item) => {
      // console.log(year, item.year, uid, item.program.raw);
      return item.year === year && item.program.raw.uid === uid;
    })[0];
  });
  return result;
};

const parseSelectedWorks = (winner, year, uid) => {
  //console.log(winner, year, slug);
  if (!winner.data.selected_works) {
    // console.log('no selected works');
    return null;
  }
  let works = winner.data.selected_works.filter(
    (work) => work.primary.award.raw.uid === uid && work.primary.year === year
  );
  if (!works || works.length === 0) {
    // console.log('no selected works found');
    return null;
  }
  return works[0].items;
};

class ProgramPage extends Component {
  state = { dialogShowing: false, dialogTitle: null, dialogContents: null };

  static getDerivedStateFromProps(props, prevState) {
    if (typeof window === "undefined") {
      return null;
    }
    let loc = props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    let routeParser = new Route(`/${loc}/:path(/:person)(/)`);

    const { path, person } = routeParser.match(props.location.pathname);
    if (!path) {
      return { dialogShowing: false, redirect: prevState.redirect || false };
    }

    let state = { dialogShowing: true, redirect: prevState.redirect || false };

    switch (true) {
      case path === "guidelines":
        state.dialogContents = (
          <GuidelinesDialogContent stateObj={state} {...props.data.page.data} />
        );
        break;
      case path === "finalist":
      case path === "winner":
        getFinalistsDialogContent(
          state,
          `/${loc}/${path}`,
          person,
          parseFinalists(
            props.data.finalists,
            props.data.page.data.year,
            props.data.page.uid
          ),
          props.data.page.data.title.text,
          props.data.page.data.year
        );
        break;
      default:
        state.dialogShowing = false;
        break;
    }
    return state;
  }

  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;
    let description = page.seo_description || globals.seo_description;
    let img_alt = page.seo_image_alt || globals.seo_image_alt || page.title;

    const galleryImage = getOgImageFromGallery(page?.body);
    let fb_image = page.seo_image
      ? page.seo_image.facebook
      : globals.seo_image.facebook;

    let twitter_image = page.seo_image
      ? page.seo_image.twitter
      : globals.seo_image.twitter;

    if (galleryImage) {
      fb_image = galleryImage;
      img_alt = galleryImage?.alt?.length > 0 ? galleryImage.alt : img_alt;
      twitter_image = galleryImage;
    }

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  positionDialog() {
    // if (this.state.dialogContents) {
    //   let dialog = this.dialog ? this.dialog.dialog : null;
    //   if (!dialog) {
    //     return;
    //   }
    //   clearTimeout(this.dialogTimeout);
    //   this.dialogTimeout = setTimeout(() => {
    //     let width = dialog.getBoundingClientRect().width;
    //     let height = dialog.getBoundingClientRect().height;
    //     dialog.style.top = `${Math.round(
    //       window.innerHeight * 0.5 - height * 0.5
    //     )}px`;
    //     dialog.style.left = `${Math.round(
    //       window.innerWidth * 0.5 - width * 0.5
    //     )}px`;
    //   }, 0);
    // }
  }
  onShowDialog = () => {
    console.log("showdialog");
    document.documentElement.style.overflow = "hidden";
  };

  checkDialog = () => {
    if (suppressDialog) return;
    if (this.dialog) {
      this.dialog.on("show", this.onShowDialog);
    }
    if (this.dialog && this.state.dialogShowing) {
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .addEventListener("click", this.hideDialog);
      this.dialog.on("hide", this.hideDialog);
      this.isDialogShowing = true;
      clearTimeout(this.dialogTimeout);
      this.dialogTimeout = setTimeout(() => {
        this.dialog.show();
        this.positionDialog();
      }, 1);
    } else if (
      this.dialog &&
      !this.state.dialogShowing &&
      this.isDialogShowing
    ) {
      this.dialog.off("hide", this.hideDialog);
      this.isDialogShowing = false;
      this.dialog.hide();
    }
  };

  componentDidMount() {
    suppressDialog = false;
    this.positionDialog();
    this.checkQueryString();
    setTimeout(() => {
      // window.scrollTo(0, 0);
      GlobalEmitter.on(events.resize, this.onResize);
      this.positionDialog();
      this.checkDialog();
    }, 0);
  }

  onResize = () => {
    this.positionDialog();
  };

  renderSlice = (slice, prevSlice, nextSlice) => {
    if (!slice) {
      return null;
    }

    let result = null;
    let Cmp = null;
    let extras = {};
    const slice_type = slice.slice_type;
    switch (true) {
      case sliceTypeMatches(slice_type, "hero"):
        slice.primary = { ...slice.primary, theme: "red" };
        if (slice.items[0].added !== true) {
          let hlink = null;
          let glink = `<a class="${heroStyles.internalLink}" href="/${this.props.data.page.slug}/guidelines" title="Prize Guidelines">Guidelines</a>`;
          if (
            this.props.data.page.uid === "rising-stars" ||
            this.props.data.page.uid === "berton-house-writers-residency" ||
            this.props.data.page.uid === "writers-trust-fellowship" ||
            this.props.data.page.uid === "writers-trust-mentorship"
          ) {
            hlink = `<a class="${
              heroStyles.internalLink
            }" href="/writers-books/programs/${
              this.props.data.page.slug.split("/")[1]
            }/all" title="Program History">Program History</a>`;
          }

          slice.items.unshift({
            heading: { html: `${glink}${hlink ? `<br/>${hlink}` : ""}` },
            item_size: `one-fifth`,
            added: true,
          });
        }
        extras.bottomTheme =
          nextSlice && nextSlice.primary && nextSlice.primary.theme
            ? nextSlice.primary.theme
            : null;
        extras.backLink = this.props.data.parentPage;
        Cmp = Hero;
        break;
      case sliceTypeMatches(slice_type, "quote"):
        Cmp = Quote;
        break;
      case sliceTypeMatches(slice_type, "quote_gallery"):
        Cmp = QuoteGallery;
        break;
      case sliceTypeMatches(slice_type, "sponsor"):
        Cmp = Sponsor;
        if (prevSlice && sliceTypeMatches(prevSlice.slice_type, "sponsor")) {
          extras.noTopPadding = true;
          extras.showHeading = false;
        }
        if (nextSlice && sliceTypeMatches(nextSlice.slice_type, "sponsor")) {
          extras.isGroup = true;
        }
        break;
      case sliceTypeMatches(slice_type, "sponsor_grid"):
        Cmp = SponsorGrid;
        break;
      case sliceTypeMatches(slice_type, "full_width_callouts"):
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "full_width_callout_list"):
        let items = slice.items;
        if (items.length && !items[0].link) {
          items = slice.items
            .map((item) => {
              return item && item.callout && item.callout.document
                ? item.callout.document[0].data
                : null;
            })
            .filter(Boolean);
        }
        slice.items = items;
        slice.primary = { ...slice.primary };
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "image_gallery"):
        Cmp = Gallery;
        break;
      case sliceTypeMatches(slice_type, "about_award_block"):
        Cmp = AboutAwardBlock;
        extras = { layout: 1 };
        break;
      case sliceTypeMatches(slice_type, "big_callout_links"):
        Cmp = BigCalloutLinks;
        break;
      case sliceTypeMatches(slice_type, "large_section_callout"):
        if (!slice.primary.theme) {
          slice.primary.theme = "white";
        }
        Cmp = LargeSectionCallout;
        break;
      case sliceTypeMatches(slice_type, "person_listing"):
        extras.slug = this.props.data.page.slug;
        extras.location = this.props.location;
        extras.dialogPrefix = "writer";
        if (this.props.data.page.uid === "berton-house-writers-residency") {
          extras.showDates = true;
        }
        if (this.props.data.page.uid === "rising-stars") {
          extras.showSelectedBy = true;
        }
        Cmp = StaffListing;
        break;
      case sliceTypeMatches(slice_type, "finalists"):
        let winner = slice.items[0];
        result = (
          <Winner
            key={`winner`}
            winner={winner}
            theme="white"
            slug={this.props.data.page.slug}
            title={this.props.data.page.data.title.text}
            showCitation={true}
            showAllInfo={true}
          />
        );
        break;
      case sliceTypeMatches(slice_type, "writers___books_carousel"):
        Cmp = WritersBooksCarousel;
        extras.showDate = true;
        break;
      case sliceTypeMatches(slice_type, "full_width_image"):
        Cmp = FullWidthImage;
        break;
      case sliceTypeMatches(slice_type, "youtube_embed"):
        slice.primary.embed_url = slice.primary.embed;
        slice.primary.showTitle = "true";
        Cmp = VideoSection;
        break;
      case sliceTypeMatches(slice_type, "social_embeds"):
        Cmp = SocialEmbeds;
        break;
      case sliceTypeMatches(slice_type, "photo_gallery_2024"):
        Cmp = Gallery2024;
        break;
      default:
        return null;
    }
    if (!Cmp && !result) {
      return null;
    }
    if (!Cmp && result) {
      return result;
    }
    return (
      <Cmp
        {...slice}
        key={slice.id}
        pageId={this.props.data.page.id}
        slug={this.props.data.page.slug}
        awardType={this.props.data.page.data.award_type}
        {...extras}
      />
    );
  };

  checkQueryString() {
    if (!this.props.location) {
      return;
    }
    const qs = querystring.parse(this.props.location.search.substr(1));
    if (qs && (qs.hasOwnProperty("winner") || qs.hasOwnProperty("finalist"))) {
      let key = Object.keys(qs)[0];
      const notInList =
        this.props.data.finalists.edges.filter(
          ({ node }) => node.slug.indexOf(qs[key]) >= 0
        ).length === 0;
      if (notInList) {
        this.setState({
          dialogShowing: false,
          redirect: `/authors/${qs[key]}/`,
        });
        return;
      }
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/${key}/${qs[key]}/`, {
          state: { modal: true },
        });
      }, 1000);
    } else if (qs && qs.hasOwnProperty("guidelines")) {
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/guidelines/`, {
          state: { modal: true },
        });
      }, 1000);
    }
  }

  hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    if (this.dialog) {
      try {
        this.dialog.dialog.parentNode
          .querySelector(".dialog-overlay")
          .removeEventListener("click", this.hideDialog);
        this.dialog.off("hide", this.hideDialog);
      } catch (e) {}
    }
    this.isDialogShowing = false;
    if (this.dialogDisabled || suppressDialog) {
      return;
    }
    let loc = this.props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    navigate(`/${loc}`, { state: { modal: true } });
  };

  componentWillUnmount() {
    this.dialogDisabled = true;
    GlobalEmitter.off(events.resize, this.onResize);
  }

  componentDidUpdate(props, state) {
    if (this.state.redirect) {
      setTimeout(() => {
        navigate(state.redirect, { state: { redirect: true } });
      }, 1);
      return;
    }
    this.checkDialog();
  }

  constructor(props) {
    super(props);
    let { page, finalists } = props.data;
    const { year, show_winner } = page.data;
    this.body = Array.from(page.data.body);

    if (
      show_winner === "yes" &&
      finalists &&
      finalists.edges &&
      finalists.edges.length
    ) {
      let hero = this.body.shift();
      this.finalists = parseFinalists(finalists, year, props.data.page.uid);
      this.selectedWorks = parseSelectedWorks(
        finalists.edges[0].node,
        year,
        props.data.page.uid
      );
      let carousel = null;
      if (this.selectedWorks && this.selectedWorks.length) {
        carousel = {
          id: `carousel=${uid()}`,
          slice_type: "writers___books_carousel",
          primary: { heading: { text: "Selected Works" }, theme: `grey` },
          showDate: true,
          items: this.selectedWorks.map((work) => ({
            item: work.book,
          })),
        };
      }
      this.body = [
        hero,
        {
          id: `finalists=${uid()}`,
          slice_type: "finalists",
          primary: {
            theme: "white",
          },
          items: this.finalists,
        },
        ...this.body,
      ];
      if (carousel) {
        this.body.splice(3, 0, carousel);
      }
    }
  }

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  render() {
    const { page } = this.props.data;
    const { globals, footerData } = this.props.pageContext;
    const { title } = page.data;
    const { dialogTitle, dialogContents } = this.state;

    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{ class: `program-page red` }}
          title={`${title.text} | Writers' Trust of Canada`}
          meta={ProgramPage.getPageMeta(
            globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {this.body &&
          this.body.length > 0 &&
          this.body.map((slice, idx) => {
            return this.renderSlice(
              slice,
              this.body[idx - 1],
              this.body[idx + 1]
            );
          })}
        <Footer data={footerData} theme={page.data.footer_theme} />
        <Dialog
          id="award-page-dialog"
          appRoot="#__gatsby"
          dialogRoot="#dialog-root"
          dialogRef={(dialog) => (this.dialog = dialog)}
          title={dialogTitle || ``}
          closeButtonContent={
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          }
          classNames={{
            element: "dialog-content",
            overlay: "dialog-overlay",
            base: "dialog",
            closeButton: "dialog-close",
            title: "is-sr-only",
          }}
        >
          {dialogContents || ``}
        </Dialog>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String, $year: Date) {
    globals: prismicSiteGlobals {
      data {
        seo_description {
          text
        }
        twitter_handle {
          text
        }
        facebook_app_id {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
    parentPage: prismicLandingPage(uid: { eq: "programs" }) {
      slug
      data {
        title {
          text
        }
      }
    }
    page: prismicProgramPage(uid: { eq: $uid }) {
      id
      uid
      slug
      data {
        snippets {
          snippet {
            text
          }
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        title {
          text
        }
        show_winner
        year
        footer_theme
        body {
          ... on PrismicProgramPageBodyHero {
            id
            slice_type
            primary {
              category {
                text
              }
              heading {
                text
              }
              button_label {
                text
              }
              button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              bottom_callout_heading {
                text
              }
              bottom_callout_large_heading {
                text
              }
              bottom_callout_paragraph {
                html
                text
              }
              bottom_callout_button_label {
                text
              }
              bottom_callout_button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              show_side_panel
              enable_footer
              enable_floating_side_panel
              show_footer_item_borders
              footer_heading {
                text
              }
            }
            items {
              item_size
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicProgramPageBodyQuote {
            id
            slice_type
            primary {
              content {
                text
              }
              person {
                text
              }
              caption {
                text
              }
              callout_label {
                text
              }
              callout_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicProgramPageBodyQuoteGallery {
            id
            slice_type
            primary {
              theme
            }
            items {
              content {
                text
                html
              }
              person {
                text
              }
              caption {
                text
              }
            }
          }
          ... on PrismicProgramPageBodyFullWidthCallouts {
            id
            slice_type
            primary {
              theme
            }
            items {
              link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category_heading {
                text
              }
              heading {
                text
              }
              label {
                text
              }
            }
          }
          ... on PrismicProgramPageBodyImageGallery {
            id
            slice_type
            primary {
              heading {
                text
              }
              facebook_gallery_id {
                text
              }
            }
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              caption {
                html
              }
            }
          }
          ... on PrismicProgramPageBodyAboutAwardBlock {
            id
            slice_type
            primary {
              category {
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicProgramPageBodyLargeSectionCallout {
            id
            slice_type
            primary {
              theme
              layout
              bleed
              category {
                text
              }
              heading {
                text
              }
              paragraph {
                html
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
            items {
              link_label {
                text
              }
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicProgramPageBodyYoutubeEmbed {
            id
            slice_type
            primary {
              theme
              youtube_redirect
              iframe_embed
              heading {
                text
              }
              embed {
                embed_url
                provider_name
                width
                height
                thumbnail_url
                title
              }
              embed_ratio
            }
          }
          ... on PrismicProgramPageBodySocialEmbeds {
            id
            slice_type
            primary {
              theme
              heading {
                text
              }
              number_of_columns
            }
            items {
              embed {
                provider_name
                embed_url
                html
              }
            }
          }
          ... on PrismicProgramPageBodyBigCalloutLinks {
            id
            slice_type
            primary {
              theme
            }
            items {
              link_label {
                text
              }
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              modal_link
            }
          }
          ... on PrismicProgramPageBodyPersonListing {
            id
            slice_type
            primary {
              theme
              heading {
                text
              }
            }
            items {
              staff_member {
                doc: document {
                  ... on PrismicWriter {
                    slug
                    modalSlug
                    data {
                      first_name {
                        text
                      }
                      last_name {
                        text
                      }
                      image {
                        url
                        dimensions {
                          width
                          height
                        }
                        carousel {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                      bio {
                        html
                      }
                      programs {
                        program {
                          raw {
                            id
                            slug
                            uid
                          }
                        }
                        year
                        citation {
                          html
                        }
                        selected_by: selected_by2 {
                          document {
                            uid
                            slug
                            data {
                              first_name {
                                text
                              }
                              last_name {
                                text
                              }
                              bio {
                                html
                              }
                              image {
                                url
                                dimensions {
                                  width
                                  height
                                }
                                carousel {
                                  url
                                  dimensions {
                                    width
                                    height
                                  }
                                }
                              }
                            }
                          }
                        }
                        start_date(formatString: "MMMM YYYY")
                        end_date(formatString: "MMMM YYYY")
                      }
                    }
                  }
                  ... on PrismicStaffMember {
                    modalSlug: uid
                    data {
                      first_name {
                        text
                      }
                      last_name {
                        text
                      }
                      image {
                        url
                        dimensions {
                          width
                          height
                        }
                      }
                      job_title {
                        text
                      }
                      email {
                        text
                      }
                      phone_number {
                        text
                      }
                      bio {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProgramPageBodyFullWidthImage {
            id
            slice_type
            primary {
              theme
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicProgramPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        uid
                        id
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProgramPageBodySponsor {
            id
            slice_type
            primary {
              sponsor {
                document {
                  data {
                    name {
                      text
                    }
                    logo {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    tagline {
                      html
                    }
                    description {
                      html
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProgramPageBodySponsorGrid {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              sponsor {
                document {
                  data {
                    name {
                      text
                    }
                    logo {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    tagline {
                      html
                    }
                    description {
                      html
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProgramPageBodyPhotoGallery2024 {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        source_code {
          text
        }
        pdf_application {
          url
        }
        external_url {
          text
        }
        guidelines_title {
          text
        }
        guidelines_subtitle {
          text
        }
        guidelines_top_paragraph {
          html
        }
        body1 {
          ... on PrismicProgramPageBody1ContentBlock {
            id
            slice_type
            primary {
              columns
              heading {
                html
              }
            }
            items {
              content {
                html
              }
            }
          }
        }
      }
    }
    finalists: allPrismicWriter(
      filter: {
        data: {
          programs: {
            elemMatch: {
              program: { raw: { uid: { eq: $uid } } }
              year: { eq: $year }
            }
          }
        }
      }
    ) {
      edges {
        node {
          slug
          data {
            selected_works {
              primary {
                award {
                  raw {
                    id
                    uid
                    slug
                  }
                }
                year
              }
              items {
                book {
                  document {
                    authorSlug
                    slug
                    data {
                      title {
                        text
                      }
                      publish_date
                      image: cover_image {
                        url
                        carousel {
                          url
                        }
                      }
                      publisher {
                        document {
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            first_name {
              text
            }
            last_name {
              text
            }
            image {
              url
              carousel {
                url
              }
            }
            alternate_image {
              url
            }
            bio {
              text
              html
            }
            programs {
              year
              program {
                raw {
                  uid
                  id
                  slug
                }
              }
              citation {
                text
                html
              }
              youtube_embed: embed {
                provider_name
                version
                embed_url
                height
              }
            }
          }
        }
      }
    }
  }
`;

export default ProgramPage;
