import React from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import { renderText, renderHtml } from 'utils/renderHelpers';
import { renderFluidImage } from 'utils/imageHelpers';

import classNames from 'classnames/bind';
import styles from './AboutAwardBlock.module.scss';

const cx = classNames.bind(styles);

export default ({ primary }) => {
  const { category, image, heading, content, layout } = primary;
  const separateHeading =
    layout === 'heading-has-own-column' || layout === 'heading has own column';
  const hasHeading = heading && heading.text && heading.text !== '';
  const hasImage = image && image.url;

  return (
    <Section className={`white ${styles.section}`}>
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(category, 'h4')}
          </Columns.Column>
          {hasImage && (
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 2 }}>
              <figure>{renderFluidImage(image)}</figure>
            </Columns.Column>
          )}
          <Columns.Column
            mobile={{ size: hasImage ? 12 : 12 }}
            tablet={{ size: hasImage ? 10 : 12 }}
          >
            <Columns mobile multiline>
              {hasHeading && separateHeading && (
                <Columns.Column mobile={{ size: 11 }} tablet={{ size: 6 }}>
                  {renderText(heading, 'h2', styles.separateHeading)}
                </Columns.Column>
              )}
              <Columns.Column
                mobile={{ size: 11 }}
                tablet={{ size: separateHeading ? 6 : 12 }}
              >
                {!separateHeading && renderHtml(heading)}
                {renderHtml(
                  content,
                  'div',
                  cx({ content: true, twoColumn: !separateHeading })
                )}
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};
